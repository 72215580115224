import request from "@/utils/requestXBT";

export async function getSendAddressAsync() {
  const response = await request({
    url: "/api/v1/homeapp/buyer/send_addresses",
    method: "get",
  });

  return response.data.data;
}

export async function getBuyerAddressesAsync() {
  const response = await request({
    url: "/api/home/buyer/addresses",
    method: "get",
  });

  for (let address of response.data.data) {
    if (address.is_default === "Y") {
      const { data } = await CheckAddressScopeAsync({
        province: address.province_name,
        city: address.city_name,
        area: address.area_name,
        address: address.detail,
      });

      const isSuccess = data.code === 200;

      address.selected = isSuccess;

      if (isSuccess) {
        address.price = data.data.price;
        address.minDeliveryNum = data.data.min_delivery_num;
      } else {
        address.price = 0;
        address.minDeliveryNum = 0;
      }
    } else {
      address.selected = false;
    }
  }

  return response.data.data;
}

export async function SaveBuyerAddressesAsync(data) {
  const response = await request({
    url: "/api/home/buyer/addresses",
    method: data.uid ? "patch" : "post",
    data,
  });

  return response.data;
}

export async function CheckAddressScopeAsync(data) {
  const response = await request({
    url: "/api/home/orders/send-flash/check",
    method: "post",
    data,
  });

  return response;
}

export async function GetStoresAsync(params) {
  const response = await request({
    url: "/api/home/stores",
    method: "get",
    params,
  });

  return response.data.data;
}

export async function GetStoreSpusAsync(uid, params) {
  const response = await request({
    url: `/api/home/store/${uid}/spus`,
    method: "get",
    params,
  });

  return response.data.data;
}

export async function GetSpuAsync(uid) {
  const response = await request({
    url: `/api/home/spus/${uid}`,
    method: "get",
  });

  const responseData = response.data;
  return responseData.code != 200 ? null : responseData.data;
}

export async function CreateOrderAsync(data) {
  const response = await request({
    url: "/api/home/orders",
    method: "post",
    data,
  });
  return response.data;
}

export async function GetProvincesAsync() {
  const response = await request({
    url: `/api/region/provinces`,
    method: "get",
  });

  return response.data.data;
}

export async function GetRegionAsync(uid) {
  const response = await request({
    url: `/api/region/children/${uid}`,
    method: "get",
  });

  return response.data.data;
}

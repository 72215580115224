<template>
  <div>
    <div
      v-if="!isNew"
      class="address-box"
      :class="{ 'address-active': value.selected }"
      @click="handleSelect"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    >
      <el-row class="header">
        <el-col :span="17" class="receiver">{{ value.name }}</el-col>
        <el-col :span="7">
          <span class="default" v-if="value.is_default === 'Y'">默认地址</span>
        </el-col>
      </el-row>
      <div class="content">
        <div>{{ value.phone }}</div>
        <div>
          {{ value.province_name }} {{ value.city_name }} {{ value.area_name }}
        </div>
        <div>{{ value.detail }}</div>
      </div>
      <el-button
        type="text"
        class="edit-button"
        v-show="showEditButton"
        @click.stop="handleEdit"
        >修改</el-button
      >
      <div class="delivery-fee" v-show="value.price > 0">
        配送费：{{ value.price }}元/件
      </div>
    </div>
    <div v-if="isNew" class="address-addnew" @click="handleAddNew">
      <div style="height: 60px">
        <i
          class="el-icon-plus"
          style="font-size: 48px; color: rgb(242, 242, 242)"
        ></i>
      </div>
      <div style="line-height: 60px; height: 60px">添加收货地址</div>
    </div>
  </div>
</template>
<script>
import { CheckAddressScopeAsync } from "@/api/quote";
export default {
  name: "QuoteAddress",
  props: {
    list: Array,
    value: Object,
    isNew: Boolean,
  },
  data() {
    return {
      showEditButton: false,
    };
  },
  methods: {
    handleAddNew() {
      this.$emit("add");
    },
    handleEdit() {
      this.$emit("edit", this.value);
    },
    handleMouseEnter() {
      this.showEditButton = true;
    },
    handleMouseLeave() {
      this.showEditButton = false;
    },
    async handleSelect() {
      const address = this.value;
      const addrList = this.list;

      const { data } = await CheckAddressScopeAsync({
        province: address.province_name,
        city: address.city_name,
        area: address.area_name,
        address: address.detail,
      });

      const result = data.data;

      if (data.code === 200) {
        for (let addr of addrList) {
          addr.selected = false;
          addr.price = 0;
          addr.minDeliveryNum = 0;
        }
        address.selected = true;
        address.price = result.price;
        address.minDeliveryNum = result.min_delivery_num;

        this.$emit("selected", JSON.parse(JSON.stringify(address)));
      } else {
        this.$alert(data.msg, "提示");
      }
    },
  },
};
</script>
<style scoped>
.address-box {
  cursor: pointer;
  float: left;
  position: relative;
  width: 200px;
  height: 120px;
  margin-right: 10px;
  border: 2px solid rgb(242, 242, 242);
}
.address-box:hover {
  border: 1px dashed #1979ff;
}
.address-active {
  border: 1px dashed #1979ff;
}
.header {
  border-bottom: 2px solid rgb(242, 242, 242);
}
.receiver {
  padding-left: 5px;
  height: 32px;
  line-height: 32px;
}
.default {
  line-height: 32px;
  font-size: 0.1vw;
  color: rgb(4, 167, 240);
}
.content {
  padding: 5px;
  font-size: 0.1vw;
  color: rgb(170, 170, 170);
}
.delivery-fee {
  position: absolute;
  right: 5px;
  bottom: 7px;
  font-size: 0.1vw;
  color: rgb(4, 167, 240);
}
.edit-button {
  position: absolute;
  left: 5px;
  bottom: 0;
  font-size: 0.1vw;
  color: rgb(4, 167, 240);
}

.address-addnew {
  cursor: pointer;
  float: left;
  position: relative;
  width: 200px;
  height: 120px;
  line-height: 120px;
  margin-right: 10px;
  border: 2px solid rgb(242, 242, 242);
  text-align: center;
}
.address-addnew:hover {
  color: #1979ff;
}
</style>

import axios from "axios";
import store from "@/store";
import {
  apiHost,
  contentType,
  method,
  requestTimeout,
  tokenName,
} from "@/config";

const instance = axios.create({
  baseURL: apiHost,
  method: method,
  timeout: requestTimeout,
  headers: {
    "Content-Type": contentType,
  },
});

instance.interceptors.request.use(
  (config) => {
    if (store.getters["user/xbtToken"] && !config.flag) {
      config.headers[tokenName] = store.getters["user/xbtToken"];
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;

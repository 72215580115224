<template>
  <div>
    <div class="content-box">
      <quote-import @change="handleImport" v-if="isImport" />
      <div class="content-title">下单信息</div>
      <el-form ref="form" :model="table" :rules="table.rules">
        <el-table ref="table" :data="table.data" :show-header="false" height="100%">
          <el-table-column>
            <template slot-scope="scope">
              <el-form-item :prop="'data.' + scope.$index + '.store'" :rules="table.rules.store">
                <el-select v-model="scope.row.store" placeholder="供应商*" :loading="loading" filterable remote
                  :remote-method="loadStoreList" @focus="focusStore(scope.row)" @change="changeStore">
                  <el-option v-for="store in storeList" :key="store.uid" :label="store.stall_name" :value="store.uid">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-form-item :prop="'data.' + scope.$index + '.spu'" :rules="table.rules.spu">
                <el-select v-model="scope.row.spu" placeholder="货号*" :loading="loading" filterable remote
                  :remote-method="loadSpuList" @focus="focusSpu(scope.row)" @change="changeSpu">
                  <el-option v-for="spu in spuList" :key="spu.uid" :label="spu.sn" :value="spu.uid">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-form-item :prop="'data.' + scope.$index + '.color'" :rules="table.rules.color">
                <el-select v-model="scope.row.color" placeholder="颜色*" :loading="loading" filterable remote
                  :remote-method="loadColorList" @focus="focusColor(scope.row)" @change="changeColor">
                  <el-option v-for="color in colorList" :key="color.color_val" :label="color.color_val"
                    :value="color.color_val">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-form-item :prop="'data.' + scope.$index + '.size'" :rules="table.rules.size">
                <el-select v-model="scope.row.size" placeholder="尺码*" :loading="loading" filterable remote
                  :remote-method="loadSizeList" @focus="focusSize(scope.row)" @change="changeSize">
                  <el-option v-for="size in sizeList" :key="size.uid" :label="size.size_val" :value="size.uid">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot-scope="scope">
              <el-form-item :prop="'data.' + scope.$index + '.quantity'" :rules="table.rules.quantity">
                <el-input-number v-model="scope.row.quantity" placeholder="数量*" :min="1" @change="calcMoney(scope.row)"
                  @keydown.tab.native="handleTabKey(scope.$index)" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot-scope="scope">
              <el-form-item :prop="'data.' + scope.$index + '.price'">
                <el-input v-model="scope.row.price" placeholder="单价*" @change="calcMoney(scope.row)" disabled />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot-scope="scope">
              <el-form-item :prop="'data.' + scope.$index + '.money'">
                <el-input v-model="scope.row.money" placeholder="金额*" disabled />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column fixed="right" width="100">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="handleDelete(scope.$index)">删除</el-button>
              <el-button size="mini" type="text" @click="handleCopy(scope)">复制</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div class="addnew-line" @click="handleAddNew">
        <el-button type="text" icon="el-icon-plus">添加商品</el-button>
      </div>
      <quote-delivery ref="delivery" @selected="calcMoney" />
    </div>
    <quote-submit :totalQty="totalQty" :totalMoney="totalMoney" :shippingFee="shippingFee" :payMoney="payMoney"
      :loading="loading" @submit="handleSubmit" />
  </div>
</template>
<script>
import {
  GetStoresAsync,
  GetStoreSpusAsync,
  GetSpuAsync,
  CreateOrderAsync,
} from "@/api/quote";
import store from "@/store";
import { getXiebutouToken } from "@/api/user";
import { xbtHost, picking_fee, checking_fee, packing_fee } from "@/config";
import QuoteImport from "../components/import";
import QuoteDelivery from "../components/delivery";
import QuoteSubmit from "../components/submit";
export default {
  name: "QuoteQuick",
  components: {
    QuoteImport,
    QuoteDelivery,
    QuoteSubmit,
  },
  data() {
    return {
      isImport: false,
      totalQty: 0,
      totalMoney: 0,
      shippingFee: 0,
      payMoney: 0,
      loading: false,
      currentRow: null,
      storeList: [],
      spuList: [],
      colorList: [],
      sizeList: [],
      table: {
        data: [],
        rules: {
          store: {
            type: "string",
            required: true,
            message: "供应商不能为空",
            trigger: "change",
          },
          spu: {
            type: "string",
            required: true,
            message: "货号不能为空",
            trigger: "change",
          },
          color: {
            type: "string",
            required: true,
            message: "颜色不能为空",
            trigger: "change",
          },
          size: {
            type: "string",
            required: true,
            message: "尺码不能为空",
            trigger: "change",
          },
          quantity: {
            type: "number",
            required: true,
            message: "数量不能为空",
            trigger: "change",
          },
        },
      },
    };
  },
  async mounted() {
    try {
      const xbtToken = await getXiebutouToken();
      await store.dispatch("user/setXbtToken", xbtToken.data);
    } catch (error) {
      console.log(error);
    }

    this.handleAddNew();
    this.isImport =
      this.$route.meta.pageConfig?.table?.searchForm?.data?.type === "import";
  },
  methods: {
    focusRow(row) {
      this.currentRow = row;
    },
    focusStore(row) {
      this.currentRow = row;
      this.loadStoreList();
    },
    focusSpu(row) {
      this.currentRow = row;
      this.loadSpuList();
    },
    focusColor(row) {
      this.currentRow = row;
      this.loadColorList();
    },
    focusSize(row) {
      this.currentRow = row;
      this.loadSizeList();
    },
    loadStoreList(query) {
      this.storeList = [];
      this.loading = true;
      GetStoresAsync({
        keyword: query || "",
      })
        .then((data) => {
          this.storeList = data.items;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadSpuList(query) {
      this.spuList = [];

      if (!this.currentRow.store) return;

      this.loading = true;
      GetStoreSpusAsync(this.currentRow.store, {
        keyword: query || "",
      })
        .then((data) => {
          this.spuList = data.items;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadColorList(query) {
      this.colorList = [];
      this.currentRow.detail = {};

      if (!this.currentRow.spu) return;

      this.loading = true;
      GetSpuAsync(this.currentRow.spu)
        .then((data) => {
          this.colorList = data.skus;
          this.currentRow.detail = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadSizeList(query) {
      this.sizeList = [];

      if (!this.currentRow.color) return;
      if (!this.currentRow.detail) return;

      const colorObj = this.currentRow.detail.skus.find(
        (s) => s.color_val == this.currentRow.color
      );

      this.sizeList = colorObj.sizes;
    },
    changeStore(val) {
      this.currentRow.spu = null;
      this.currentRow.color = null;
      this.currentRow.size = null;
      this.currentRow.price = null;
      this.currentRow.money = null;
      this.currentRow.detail = {};
    },
    changeSpu(val) {
      this.currentRow.color = null;
      this.currentRow.size = null;
      this.currentRow.price = null;
      this.currentRow.money = null;
      this.currentRow.detail = {};
    },
    changeColor(val) {
      this.currentRow.size = null;
      this.currentRow.price = null;
      this.currentRow.money = null;
    },
    changeSize(val) {
      const colorObj = this.currentRow.detail.skus.find(
        (s) => s.color_val == this.currentRow.color
      );
      if (colorObj) {
        const sizeObj = colorObj.sizes.find((s) => s.uid == val);
        if (sizeObj) {
          this.currentRow.price = sizeObj.price;
        }
      }
      this.calcMoney(this.currentRow);
    },
    handleAddNew() {
      this.table.data.push({
        store: null,
        spu: null,
        color: null,
        size: null,
        quantity: null,
        price: null,
        money: null,
        detail: {},
      });
    },
    handleDelete(index) {
      this.table.data.splice(index, 1);
    },
    handleCopy(scope) {
      this.currentRow = JSON.parse(JSON.stringify(scope.row));
      this.table.data.splice(scope.$index + 1, 0, this.currentRow);
      this.calcMoney();
    },
    handleTabKey(index) {
      if (index == this.table.data.length - 1) {
        this.handleAddNew();
      }
    },
    calcMoney(row) {
      //行合计
      if (row) {
        row.money = Number(row.quantity || 0) * Number(row.price || 0);
        row.money = row.money.toFixed(2);
      }

      //总件数
      this.totalQty = this.table.data.reduce(
        (sum, e) => sum + Number(e.quantity || 0),
        0
      );

      //总货款
      this.totalMoney = this.table.data.reduce(
        (sum, e) => sum + Number(e.money || 0),
        0
      );
      this.totalMoney = this.totalMoney.toFixed(2);

      //总运费
      const deliveryAddr = this.$refs.delivery.selectedAddr;
      if (deliveryAddr) {
        this.shippingFee =
          Number(this.totalQty || 0) * Number(deliveryAddr.price || 0);
      }
      this.shippingFee = this.shippingFee.toFixed(2);

      //实付金额
      this.payMoney =
        Number(this.totalMoney || 0) + Number(this.shippingFee || 0);
      this.payMoney = this.payMoney.toFixed(2);
    },
    handleSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;

        const deliveryAddr = this.$refs.delivery.selectedAddr;
        if (!deliveryAddr) {
          this.$message({
            type: "warning",
            message: "请选择收货地址",
          });
          return;
        }

        this.$confirm("您确定要提交订单？", "提示", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
        }).then(() => {
          let order = {
            consignee_info: {},
            coupons: [],
            use_default_deliver: "Y", //使用平台地址
            deliver_info: {},
            deliver_type: 1, //1-有货先发，2-货齐再发
            store_list: {},
            sku_list: [],
            type: "normal",
          };

          //收货信息
          order.consignee_info = {
            province: deliveryAddr.province_name,
            city: deliveryAddr.city_name,
            area: deliveryAddr.area_name,
            address: deliveryAddr.detail,
            name: deliveryAddr.name,
            phone: deliveryAddr.phone,
            area_code: "", //区号
            tel: "", //固话
          };

          //档口信息
          let stores = [];
          for (const item of this.table.data) {
            if (stores.filter((s) => s === item.store).length <= 0) {
              stores.push(item.store);
            }
          }

          for (const item of stores) {
            order.store_list[item] = {
              deliverer: 4, //1-捕快闪发，3-上门自提，4-捕快闪送
              express_template_uid: "",
              picking_fee: picking_fee,
              checking_fee: checking_fee,
              packing_fee: packing_fee,
              special_fee: [],
            };
          }

          //商品信息
          for (const item of this.table.data) {
            order.sku_list.push({
              sku_uid: item.size,
              quantity: item.quantity,
            });
          }

          //提交订单
          this.loading = true;
          CreateOrderAsync(order)
            .then((result) => {
              if (result.code === 200) {
                const xbtToken = store.getters["user/xbtToken"];
                const arrToken = xbtToken.split(" ");
                const tokenType = arrToken[0];
                const token = arrToken[1];
                const uid_list = result.data.items.join("-");
                const payUrl = `${xbtHost}/order/pay/index.html?uid_list=${uid_list}&token_type=${tokenType}&token=${token}`;
                window.open(payUrl);

                //清空表数据
                this.table.data = [];
                this.totalQty = 0;
                this.totalMoney = 0;
                this.shippingFee = 0;
                this.payMoney = 0;
                this.handleAddNew();
                this.$refs.form.resetFields();
              } else {
                this.$alert(result.msg, "提示");
              }
            })
            .finally(() => {
              this.loading = false;
            });
        });
      });
    },
    handleImport(data) {
      for (const item of data) {
        if (!this.storeList.some((s) => s.uid == item.store)) {
          this.storeList.push({
            uid: item.store,
            stall_name: item.store_name,
          });
        }

        if (!this.spuList.some((s) => s.uid == item.spu)) {
          this.spuList.push({
            uid: item.spu,
            sn: item.spu_sn,
          });
        }

        if (!this.colorList.some((c) => c.color_val == item.color)) {
          this.colorList.push({
            color_val: item.color,
          });
        }

        if (!this.sizeList.some((s) => s.uid == item.size)) {
          this.sizeList.push({
            uid: item.size,
            size_val: item.size_val,
          });
        }
      }
      this.table.data = data;
      this.calcMoney();
    },
  },
};
</script>
<style lang="scss" scoped>
.content-box {
  position: absolute;
  right: 0;
  left: 0;
  top: 30px;
  bottom: 70px;
  overflow: auto;
}

.content-title {
  margin: 10px 10px 0 10px;
  font-weight: bold;
  font-size: 16px;
}

.addnew-line {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px dashed gray;
  margin: 0 110px 0 10px;
}
</style>

<template>
  <div class="delivery-box">
    <div class="delivery-title">配送信息</div>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="捕快闪送" name="first">
        <div class="tip-arrow" />
        <div class="tip-info">
          同城配送：目前仅支持幸福街周边10公里范围内配送
        </div>
        <div style="margin-top: 10px">
          <el-radio>常用收货地址</el-radio>
          <div style="margin-top: 10px">
            <quote-address v-for="(address, index) of addressList" :key="index" v-bind:value="address" :list="addressList"
              @selected="handleSelected" @edit="handleEditAddress" />
            <quote-address :isNew="true" @add="handleAddAddress" />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane disabled label="捕快闪提" name="second">捕快闪提</el-tab-pane>
    </el-tabs>
    <el-dialog title="收货地址" :visible.sync="dialogVisible">
      <el-form ref="form" :model="form.data" :rules="form.rules" label-width="120px">
        <el-form-item label="所在区域：" prop="region">
          <el-cascader ref="cascaderRegion" v-model="form.data.region" :props="props" @change="handleRegionChange" />
        </el-form-item>
        <el-form-item label="详细地址：" prop="detail">
          <el-input v-model="form.data.detail" type="textarea" :rows="5" />
        </el-form-item>
        <el-form-item label="收货人：" prop="name">
          <el-input v-model="form.data.name" />
        </el-form-item>
        <el-form-item label="手机号码：" prop="phone">
          <el-input v-model="form.data.phone" />
        </el-form-item>
        <el-form-item label="默认地址：">
          <el-checkbox v-model="form.data.isDefault">设为默认地址</el-checkbox>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" v-loading="loading" @click="handleSaveAddress">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import QuoteAddress from "../components/address";
import {
  getBuyerAddressesAsync,
  GetProvincesAsync,
  GetRegionAsync,
  SaveBuyerAddressesAsync,
} from "@/api/quote";
export default {
  name: "QuoteDelivery",
  components: {
    QuoteAddress,
  },
  data() {
    return {
      loading: false,
      activeName: "first",
      addressList: [],
      selectedAddr: null,
      dialogVisible: false,
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          if (level > 0) {
            GetRegionAsync(node.value).then((res) => {
              let nodes = [];
              for (const item of res) {
                nodes.push({
                  value: item.uid,
                  label: item.name,
                  leaf: level >= 2,
                });
              }
              resolve(nodes);
            });
          } else {
            GetProvincesAsync().then((res) => {
              let nodes = [];
              for (const item of res) {
                nodes.push({
                  value: item.uid,
                  label: item.name,
                });
              }
              resolve(nodes);
            });
          }
        },
      },
      form: {
        data: {},
        rules: {
          region: {
            type: "array",
            required: true,
            message: "请选择所属区域",
            trigger: "change",
          },
          detail: {
            type: "string",
            required: true,
            message: "请输入详细地址",
            trigger: "change",
          },
          name: {
            type: "string",
            required: true,
            message: "请输入收货人",
            trigger: "change",
          },
          phone: [
            { required: true, message: "请输入手机号码", trigger: "blur" },
            {
              min: 11,
              max: 11,
              message: "请输入11位手机号码",
              trigger: "blur",
            },
            {
              pattern:
                /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
              //pattern: /^1[3456789]\d{9}$/,
              message: "请输入正确的手机号码",
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.$nextTick(async () => {
      await this.loadAddressesAsync();
    });
  },
  methods: {
    initFormData() {
      this.form.data = {
        uid: "",
        name: "",
        phone: "",
        tel: "",
        tel_areacode: "",
        province: "",
        province_uid: "",
        city: "",
        city_uid: "",
        area: "",
        area_uid: "",
        detail: "",
        region: [],
        isDefault: false,
      };
    },
    async loadAddressesAsync() {
      this.addressList = await getBuyerAddressesAsync();
      this.selectedAddr = this.addressList.find(
        (addr) => addr.selected === true
      );
    },
    handleSelected(address) {
      this.selectedAddr = JSON.parse(JSON.stringify(address));
      this.$emit('selected', this.selectedAddr);
    },
    handleAddAddress() {
      this.initFormData();

      if (this.$refs.cascaderRegion) {
        this.$refs.cascaderRegion.inputValue = null;
      }

      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }

      this.dialogVisible = true;
    },
    async handleEditAddress(address) {
      this.form.data = address;

      this.form.data["region"] = [
        address.province_uid,
        address.city_uid,
        address.area_uid,
      ];

      if (this.$refs.cascaderRegion) {
        this.$refs.cascaderRegion.inputValue = `${address.province_name} / ${address.city_name} / ${address.area_name}`;
      }

      this.form.data["isDefault"] = address.is_default === "Y";

      this.dialogVisible = true;
    },
    handleRegionChange(val) {
      this.form.data.province_uid = val[0];
      this.form.data.city_uid = val[1];
      this.form.data.area_uid = val[2];
    },
    handleSaveAddress() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;

        this.form.data.is_default = this.form.data.isDefault ? "Y" : "N";

        this.loading = true;
        SaveBuyerAddressesAsync(this.form.data)
          .then(async (res) => {
            this.dialogVisible = false;
            await this.loadAddressesAsync();
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>
<style scoped>
.delivery-box {
  margin: 10px;
}

.delivery-title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}

::v-deep .el-tabs__header {
  margin: 0;
}

.tip-arrow {
  width: 0;
  height: 0;
  margin-left: 35px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 12px solid rgb(228, 240, 255);
}

.tip-info {
  width: 280px;
  padding: 5px;
  font-size: 12px;
  border-radius: 5px;
  background-color: rgb(228, 240, 255);
}
</style>

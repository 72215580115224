<template>
  <div class="submit-box">
    <span class="submit-info">合计共 {{ totalQty }} 件</span>
    <span class="submit-info">货款：{{ totalMoney }} 元</span>
    <span class="submit-info">运费：{{ shippingFee }} 元</span>
    <span class="submit-real">实付金额：{{ payMoney }} 元</span>
    <el-button type="primary" class="submit-button" v-loading="loading" @click="handleSubmit"
      >提交订单</el-button
    >
  </div>
</template>
<script>
export default {
  name: "QuoteSubmit",
  props: ["totalQty", "totalMoney","shippingFee","payMoney","loading"],
  methods: {
    handleSubmit() {
      this.$emit("submit");
    },
  },
};
</script>
<style scoped>
.submit-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 70px;
  line-height: 70px;
  background-color: rgb(215, 215, 215);
  z-index: 999;
}
.submit-button {
  position: absolute;
  right: 0;
  width: 120px;
  height: 70px;
  font-size: 16px;
}
.submit-info {
  font-size: 15px;
  font-weight: bold;
  margin-left: 20px;
}
.submit-real {
  font-size: 20px;
  font-weight: bold;
  margin-left: 20px;
}
</style>
<style>
.atooltip {
  background: rgb(2, 166, 240) !important;
}
.atooltip.el-tooltip__popper[x-placement^="top"] .popper__arrow {
  border-top-color: rgb(2, 166, 240);
}
.atooltip.el-tooltip__popper[x-placement^="top"] .popper__arrow:after {
  border-top-color: rgb(2, 166, 240);
}
</style>

<template>
  <div>
    <el-row style="margin: 10px 10px 0 10px">
      <el-col :span="12">
        <el-upload
          ref="uploadXls"
          action=""
          drag
          :limit="1"
          :auto-upload="false"
          :show-file-list="false"
          :on-change="handleChange"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将Excel文件拖到此处，或<em>点击上传</em>
          </div>
        </el-upload>
      </el-col>
      <el-col :span="12">
        <div>
          <h5>使用帮助：</h5>
          <div style="color: gray">
            1.下载表格模板
            <el-button type="text" @click="handleDownXls">下载模板</el-button>
          </div>
          <div style="color: gray">2.支持导入格式 .csv,.xls,.xlxs</div>
          <div style="color: gray">3.一次性最多导入500单</div>
          <div style="color: gray">
            4.配置商品关联
            <el-button type="text" @click="handleRelated">商品关联</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import XLSX from "xlsx";
import { GetSpuAsync } from "@/api/quote";
export default {
  name: "QuoteImport",
  methods: {
    async handleDownXls() {
      var a = document.createElement("a");
      a.href = "/static/Upload/快速下单模板.xls";
      a.download = "快速下单模板.xls";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    async handleChange(file) {
      const loading = this.$loading({
        lock: true,
        text: "正在导入中......"
      });
      try {
        const fileRaw = file.raw;
        if (fileRaw) {
          if (
            fileRaw.type == "application/vnd.ms-excel" ||
            fileRaw.type ==
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            const excelData = await this.excelToJson(fileRaw);

            let i = 0;
            let logs = [];
            let rows = [];
            for (const item of excelData) {
              let uid = item["鞋捕头商品链接/鞋捕头商品uid"];
              const color = item["颜色"];
              const size = item["尺码"];
              const qty = item["数量(件)"];

              //解析url
              if (
                uid.includes(
                  "https://www.xiebutou.com/store/goods.html?uid="
                ) ||
                uid.includes(
                  "https://www-dev.xiebutou.net/store/goods.html?uid="
                )
              ) {
                uid = uid.split("=")[1];
              }

              //整理数据
              const spuObj = await GetSpuAsync(uid);
              if (spuObj) {
                const storeObj = spuObj.store;
                const colorObj = spuObj.skus.find((s) => s.color_val == color);
                if (colorObj) {
                  const sizeObj = colorObj.sizes.find(
                    (s) => s.size_val == size
                  );
                  if (sizeObj) {
                    rows.push({
                      store: storeObj.uid,
                      store_name: storeObj.stall_name,
                      spu: spuObj.uid,
                      spu_sn: spuObj.sn,
                      color: colorObj.color_val,
                      size: sizeObj.uid,
                      size_val: sizeObj.size_val,
                      quantity: qty,
                      price: sizeObj.price,
                      money: (Number(qty) * Number(sizeObj.price)).toFixed(2),
                    });
                  } else {
                    logs.push(`第 ${i + 2} 行的尺码 ${size} 无效`);
                  }
                } else {
                  logs.push(`第 ${i + 2} 行的颜色 ${color} 无效`);
                }
              } else {
                logs.push(`第 ${i + 2} 行的商品链接/商品uid无效`);
              }

              i++;
            }

            if (logs.length <= 0) {
              this.$emit("change", rows);
            } else {
              this.$alert(logs.join("<br/>"), "提示", {
                dangerouslyUseHTMLString: true,
              });
            }

            this.$refs.uploadXls.clearFiles();
          } else {
            this.$message({
              type: "warning",
              message: "附件格式错误，请重新上传！",
            });
          }
        } else {
          this.$message({
            type: "warning",
            message: "请上传附件！",
          });
        }
      } finally {
        loading.close();
      }
    },
    excelToJson(file) {
      return new Promise((resolve, reject) => {
        if (file instanceof File) {
          const reader = new FileReader();
          reader.onloadend = (e) => {
            const buffer = reader.result;

            const workbook = XLSX.read(buffer, {
              type: "array",
            });

            const result = XLSX.utils.sheet_to_json(
              workbook.Sheets[workbook.SheetNames[0]]
            );

            resolve(result);
          };
          reader.readAsArrayBuffer(file);
        } else {
          reject(new Error("入参不是 File 类型"));
        }
      });
    },
    handleRelated() {},
  },
};
</script>
